import NumberWithDot from "../../utilities/NumberWithDot";
import useHttp from "../parts/useHttp";

const SliderTop = (props) => {
  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers`;

  const {headers, loading, error} = useHttp(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) return console.log(error);
  if (!headers?.['total-count']) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }
  function roundToThousands(number) {
    if (!number) return 0;
    return Math.floor(number / 1000) * 1000;
  }
  const formattedCount = roundToThousands(headers?.['total-count']);

  return(
    <>
      <div className="slider slider-top slider-top-green">
        <div className="container">
          <div className="row row-flex justify-content-center align-items-center">
            <div className="col-md-7 flex-fill my-4">
              <h1 className="h1 slgo-small-title font-weight-bold">{props.title}</h1>
              <h2 className="slgo-small-title">Oltre <span className="font-weight-bold">{formattedCount}</span> ambulatori e strutture sanitarie in tutta Italia</h2>
            </div>
            <div className="col-md-5"><img src="/img/header-image-trova-centro-medico.png" alt="Trova centro medico" /></div>{/* <img src="/img/header-image-[slug-pagina].png" /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SliderTop;