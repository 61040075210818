import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useHttp from "../pages/parts/useHttp"; // Assuming this is your custom HTTP hook

// Custom hook to track when an element enters the viewport
const useInViewTracking = (url, trackingData, isVip, threshold = 0.1) => {
  const { sendRequest } = useHttp();
  const [hasTracked, setHasTracked] = useState(false);

  // Use Intersection Observer to track visibility
  const { ref, inView } = useInView({
    threshold, // Use the passed threshold or the default value
    triggerOnce: true, // Only trigger once
  });

  useEffect(() => {
    if (inView && !hasTracked && isVip) {
      // Call the API when the element comes into view and it is a VIP
      sendRequest("POST", url, trackingData)
        .then(() => {
          setHasTracked(true); // Mark that the API call has been made
        })
        .catch((error) => {
          console.error("Tracking API call failed", error);
        });
    }
  }, [inView, sendRequest, trackingData, hasTracked, url]);

  return { ref };
};

export default useInViewTracking;
