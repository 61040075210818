import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import { getImageUrl } from "../../utilities/getImageUrl";
import useInViewTracking from "../../hooks/useInViewTracking"; // Import the custom hook
import IsBot from "../../utilities/IsBot";

function ShowSSNTags({ ssnCompliant }) {
  if (ssnCompliant) {
    return (
      <>
        <small>SSN</small>
        <small>Convenzionato</small>
      </>
    );
  }
  return null;
}

const CenterCard = (props) => {
  const { centerId, centerSlug, serviceId, serviceSlug, centerName, distance, fullAddress, categoryName, ssnCompliant, logo, caller, vip, phoneNumber, email } = props;
  const [logoUrl, setLogoUrl] = useState('');
  const centerTypeClass = vip ? "slgo-center slgo-vip-center" : "slgo-center"; 
  const centerType = props.public ? "Pubblico" : "Privato"; 

  let slug = centerSlug;
  let linkUrl = '/centro-medico/';
  if (caller === 'centersCategory' || caller === 'centersService') {
    slug = serviceSlug;
    linkUrl = '/prestazione-medica/';
  }

  const advApiPath = caller === 'centersCategory' || caller === 'centersService' 
  ? 'adv_services_consume_token' 
  : 'adv_centers_consume_token';

  const trackingParams = {
    component: "CenterCard",
    [caller === 'centersCategory' || caller === 'centersService' ? "service_ids" : "center_ids"]: 
      caller === 'centersCategory' || caller === 'centersService' ? [serviceId] : [centerId],
    is_bot: IsBot()
  };

  // Use the custom hook for tracking, passing `vip` value
  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${advApiPath}`;
  const { ref } = useInViewTracking(url, trackingParams, vip);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      const url = await getImageUrl(logo);
      setLogoUrl(url);
    };

    fetchLogoUrl();
  }, [logo]);

  return (
    <div ref={ref} className={`${centerTypeClass} container mb-4 slgo-grey-bg`}>
      <div className={`anchor-center`} id={`anchor-center-${centerId}`} />
      <div className="row pt-4 pb-0 px-2">
        <div className="col-md-3">
          <Link to={`${linkUrl}${slug}`} className="d-block mb-4 slgo-center-img">
            <img src={logoUrl} width="296" height="240" className="slgo-img-h-auto" alt={FormatCenterName(centerName)} />
          </Link>
        </div>
        <div className="col-md-9">
          <h4 className="slgo-small-title mb-2">
            <Link to={`${linkUrl}${slug}`}>{FormatCenterName(centerName)}</Link>
          </h4>
          {distance && (
            <p className="slgo-distance slgo-dark-text mb-2 d-flex gap-2">
              {distance.toString().replace(".", ",")} km di distanza
            </p>
          )}
          <p className="slgo-address mb-2 mt-4 d-flex gap-2 font-weight-bold">{fullAddress}</p>
          <p className="slgo-center-type mb-2 d-flex gap-2">{centerType}</p>
          {categoryName && (
            <p className="slgo-description mb-2 d-flex gap-2">{Capitalize(categoryName)}</p>
          )}
          <div className="tags ps-4">
            <ShowSSNTags ssnCompliant={ssnCompliant} />
          </div>
          <div className="float-end mt-3 mb-2">
            {vip && phoneNumber && (
              <a href={`tel://${phoneNumber}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-phone me-2 mb-2"></a>
            )}
            {vip && email && (
              <a href={`mailto:${email}`} className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-email me-2 mb-2"></a>
            )}
            <Link to={`${linkUrl}${slug}`} className="btn btn-primary slgo-light-text mb-2">
              Visualizza soluzione
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterCard;
