import Select from "react-select";
import useHttp from "../parts/useHttp";
import { customStyles } from './SelectStyles';
import SetDropdownDefaultValue from "../../utilities/SetDropdownDefaultValue";

const RegionDropdown = ({regionId, handleRegionChange, setRegionName}) => {

  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers_region_ids?do_not_page=true`;
  const { data, loading, error } = useHttp(url);

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if(!data?.data) {
    console.warn(`GET ${url} empty response`)
    return(
      <Select
        name="region"
        options=""
        defaultValue=""
        placeholder={<div>Errore: lista non disponibile</div>}
        styles={customStyles}
      />
    );
  }

  let regionOptions = [];
  data?.data.map(
    (region) => {
      regionOptions.push({value: region.id, label: region.region_name})
      if (region.id === regionId) {
        setRegionName(region.region_name)
      }
    }
  );

  const defaultValue = SetDropdownDefaultValue(regionOptions, regionId);

  return(
    <Select
      name="region"
      options={regionOptions}
      defaultValue={defaultValue}
      onChange={(event) => handleRegionChange(event)}
      placeholder={<div>Seleziona la regione</div>}
      styles={customStyles}
    />
  );

}

export default RegionDropdown;