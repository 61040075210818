const IsBot = () => {
  const botPattern = /bot|crawler|spider|crawling|google|bing|yahoo|duckduck|baidu|yandex|facebook|twitter|slack|discord/i;
  const isBotByUserAgent = botPattern.test(navigator.userAgent);

  let isHuman = false;
  const markHuman = () => { isHuman = true; };

  window.addEventListener('mousemove', markHuman, { once: true });
  window.addEventListener('keydown', markHuman, { once: true });

  return isBotByUserAgent && !isHuman;
};

export default IsBot;
