import Select from "react-select";
import useHttp from "../parts/useHttp";
import { customStyles } from './SelectStyles';
import SetDropdownDefaultValue from "../../utilities/SetDropdownDefaultValue";

const ProvinceDropdown = ({regionId, provinceId, handleProvinceChange, setProvinceName}) => {
  let provinceOptions = [];
  
  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers_province_ids?region_ids=${regionId}&do_not_page=true`;
  const { data, loading, error } = useHttp(url);

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if(!data?.data) {
    console.warn(`GET ${url} empty response`)
    return(
      <Select
        name="province"
        options=""
        defaultValue=""
        placeholder={<div>Errore: lista non disponibile</div>}
        styles={customStyles}
      />
    );
  }

  data?.data.map(
    (province) => {
      provinceOptions.push({value: province.id, label: province.province_name})
      if (province.id === provinceId) {
        setProvinceName(province.province_name)
      }
    }
  );

  const defaultValue = SetDropdownDefaultValue(provinceOptions, provinceId);

  return(
    <Select
      name="province"
      options={provinceOptions}
      defaultValue={defaultValue}
      onChange={(event) => handleProvinceChange(event)}
      placeholder={<div>Seleziona la provincia</div>}
      styles={customStyles}
    />
  );

}

export default ProvinceDropdown;