import useHttp from "../../parts/useHttp";
import CentersCategorySeo from "./CentersCategorySeo";
import CentersBody from "../CentersBody";
import Titleize from "../../../utilities/Titleize";
import PrintHtmlText from "../../../utilities/PrintHtmlText";

const CentersCategory = ({ regionId, provinceId, localityId, keyText, activeTab, publicCenter, categorySlug, page }) => {

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/categories/${categorySlug}`;

  const baseUrl = window.location.origin.toString() + `/trova-centro-medico-con-prestazione/${categorySlug}`;

  const {data, loading, error} = useHttp(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  return(
    <>
      <CentersCategorySeo
        title={data?.data.seo_title}
        description={data?.data.seo_description}
        regionId={regionId} 
        provinceId={provinceId} 
        localityId={localityId}
        keyText={keyText}
      />
      <div className="single-service-content single-service-content-no-center pt-5 pb-3">
        <div className="container container-top container-top-2 slgo-mobile-w-94 py-5 px-4 px-sm-5 mt-0 mt-md-5 mb-4 mb-md-5">
          <div className="row g-5">
            <div className="col-md-2">
              <img src="/img/placeholder-prestazioni.png" width="164" height="165" className="slgo-img-h-auto" alt="Prestazione sanitaria"/>
            </div>
            <div className="col-md-10 slgo-desc-service">
              <div className="slgo-desc-service-content m-0">
                <h1 className="slgo-title slgo-text-capitalized font-weight-bold mb-3">{Titleize(data?.data.category_name)}</h1>
                {(data?.data.description) &&
                  <p><PrintHtmlText text={data?.data.description} /></p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="single-service-content">
        <div className="container slgo-mobile-w-94 p-0">
          <div className="row g-5">
            <div className="col-md-12">
              <h5 className="slgo-light-text font-weight-bold pb-2">Dove trovo la prestazione sanitaria che mi interessa?</h5>
            </div>
          </div>
        </div>
      </div>

      <CentersBody regionId={regionId} provinceId={provinceId} localityId={localityId} keyText={keyText} activeTab={activeTab} baseUrl={baseUrl} publicCenter={publicCenter} categorySlug={categorySlug} categoryName={data?.data.category_name} page={page} caller='centersCategory' />
      
    </>
  );
}

export default CentersCategory;