import Capitalize from "../../utilities/Capitalize";
import FormatCenterName from "../../utilities/FormatCenterName";
import IsBot from "../../utilities/IsBot";
import useHttp from "./useHttp";
import useInViewTracking from "../../hooks/useInViewTracking";
import { getImageUrl } from "../../utilities/getImageUrl";
import React, { useState, useEffect } from "react";

const CentersVipCarousel = ({ id, caller, threshold = 0.4 }) => {
  const [isBot, setIsBot] = useState("true");
  const [logoUrls, setLogoUrls] = useState({}); // Oggetto per memorizzare gli URL dei loghi
  const [objectIds, setObjectIds] = useState([]); // Stato per memorizzare gli ID dei centri

  const apiPath = caller === "service" ? "adv_services_near_service" : "adv_centers_near_center";
  const title = caller === "service"
    ? "Centri medici vicini con la stessa prestazione sanitaria"
    : "Centri medici vicini";

  const url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${apiPath}/${id}?is_bot=${isBot}`;

  useEffect(() => {
    setIsBot(IsBot());
  }, []);

  const { data, loading, error } = useHttp(url);

  // Usa useEffect per aggiornare i loghi per ogni centro e memorizzare gli ID
  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      let ids = [];

      if (caller === "center") {
        // Estrai gli ID dei centri
        ids = data.data.map((center) => center.id);
      } else if (caller === "service") {
        // Estrai gli ID dei servizi
        ids = data.data.map((center) => center.service.id);
      }

      // Memorizza gli ID
      setObjectIds(ids);

      // Carica gli URL dei loghi
      data.data.forEach(async (center) => {
        const url = await getImageUrl(center.logo);
        setLogoUrls((prevLogoUrls) => ({ ...prevLogoUrls, [center.slug]: url })); // Salva l'URL del logo in base allo slug
      });
    }
  }, [data, caller]);


  // Determina il percorso API e i parametri in base al valore di caller
  const advApiPath = caller === "center" 
  ? "adv_centers_consume_token" 
  : "adv_services_consume_token";

  const trackingParams = {
  component: "CentersVipCarousel",
  [caller === "center" ? "center_ids" : "service_ids"]: objectIds, // Usa la chiave dinamica
  is_bot: isBot
  };

  // Hook per il tracciamento della visibilità
  let advUrl = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${advApiPath}`;
  const { ref } = useInViewTracking(advUrl, trackingParams, true, threshold);

  if (loading) {
    return null;
  }

  if (error) return `Errore: ${error.message}`;

  if (!data || (Array.isArray(data.data) && data.data.length === 0)) {
    console.log(`No data returned from GET ${url}`);
    return null;
  }

  return (
    <>
      <div className="related-centers slgo-single-center-related-centers mb-5" ref={ref}>
        <div className="container pt-0 pb-5">
          <div className="row">
            <div className="col-md-12 pb-3 px-3 px-sm-0">
              <h4 className="slgo-light-text font-weight-bold">{title}</h4>
            </div>
          </div>
          <div className="slgo-ads-center row row-grid px-3 px-sm-0">
            {data?.data.map((center) => (
              <div className="slgo-single-ads-center" key={center.slug}>
                <div className="slgo-single-ads-center-img">
                  <a
                    href={`/centro-medico/${center.slug}`}
                    className="slgo-center-img slgo-center-img-bg d-block"
                    style={{ backgroundImage: `url(${logoUrls[center.slug]})` }}
                  ></a>
                </div>
                <div className="slgo-single-ads-center-title py-3">
                  <h6 className="p-0 my-0 mx-md-0 mx-2">
                    <a className="slgo-light-text" href={`/centro-medico/${center.slug}`}>
                      {FormatCenterName(center.center_name)}
                    </a>
                  </h6>
                </div>
                <div className="slgo-single-ads-center-icons p-0">
                  {center.phone_number && (
                    <a
                      href={`tel://${center.phone_number}`}
                      className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-phone"
                    ></a>
                  )}
                  {center.emails && (
                    <a
                      href={`mailto:${center.emails}`}
                      className="btn btn-primary btn-no-arrow btn-only-icon slgo-btn-single-center slgo-btn-email"
                    ></a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CentersVipCarousel;
