import CenterServiceBookingInfoDetails from "./CenterServiceBookingInfoDetails";
import useHttp from "../parts/useHttp";

const CenterServiceBookingInfo = ({ centerId }) => {

  let url = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/centers/${centerId}`;

  const {data, loading, error} = useHttp(url);
  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    return(
      <>
      <div className="col-md-12 text-center p-5">
        <p>Nessuna soluzione trovata. Prova a rivedere i parametri dei filtri.</p>
      </div>
      </>
    );
  }

  return(
    <>
      {(data?.data.generic_phone_booking_number || data?.data.generic_phone_booking_number_by_mobile || data?.data.generic_phone_booking_number_by_mobile || data?.data.generic_system_booking_url || data?.data.ssn_phone_booking_number || data?.data.ssn_phone_booking_number_by_mobile || data?.data.regional_phone_booking_number || data?.data.regional_phone_booking_number_by_mobile || data?.data.ssn_email_booking_address || data?.data.ssn_system_booking_url || data?.data.private_phone_booking_number || data?.data.private_phone_booking_number_by_mobile || data?.data.private_email_booking_address || data?.data.private_system_booking_url) &&
      <CenterServiceBookingInfoDetails data={data} />
    }
    </>
  );

}

export default CenterServiceBookingInfo;