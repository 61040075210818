import useHttp from "../parts/useHttp";
import CenterCard from "./CenterCard";
import BuildFullAddress from "../../utilities/BuildFullAddress";
import React, { useState, useEffect } from "react";
import IsBot from "../../utilities/IsBot";

const CentersVipCard = (props) => {
  const [location, setLocation] = useState({ lat: null, lng: null });

  useEffect(() => {
    if (!navigator.geolocation) {
      console.log("Geolocation not supported");
      return;
    }

    const successCallback = (position) => {
      const { lat, lng } = props;
      setLocation({ lat: lat || position.coords.latitude, lng: lng || position.coords.longitude });
    };

    const errorCallback = () => {
      console.log("Unable to retrieve your location");
      const { lat, lng } = props;
      setLocation({ lat: lat || null, lng: lng || null });
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, [props.lat, props.lng]);

  const queryParams = {
    category_id: props.categoryId,
    category_slug: props.categorySlug,
    lat: location.lat,
    lng: location.lng,
    is_bot: IsBot()
  };

  const queryString = Object.entries(queryParams)
    .filter(([key, value]) => value != null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

  var apiPath = 'adv_services';
  if (props.caller == 'centers') apiPath = 'adv_centers';
  const apiUrl = `${process.env.REACT_APP_HTTP_PROTO}://${process.env.REACT_APP_BE_HOST}/api/v1/${apiPath}?${queryString}`;

  const { data, loading, error } = useHttp(apiUrl);

  if (loading) {
    {/* return (
      <div className="loader-container text-center">
        <img src="/img/icons/preloader.svg" alt="Caricamento..." className="loader-img" />
      </div>
    ); */}
    return '';
  }
  if (error) console.log(error);
  if (!data) {
    console.log(`No data returned from GET ${apiUrl}`);
  }

  return (
    <>
      {data?.data.map((center) => (
        <CenterCard
          key={center.id}
          centerId={center.id}
          centerName={center.center_name}
          fullAddress={
            <BuildFullAddress
              streetName={center.geo_data.street_name}
              streetNumber={center.geo_data.street_number}
              postalCode={center.geo_data.postal_code}
              localityName={center.geo_data.locality_name}
              provinceName={center.geo_data.province_name}
              regionName={center.geo_data.region_name}
            />
          }
          public={center.public}
          logo={center.logo}
          description={center.description}
          ssnCompliant={center.ssn_compliant}
          centerSlug={center.slug}
          serviceSlug={center?.service?.slug}
          serviceId={center?.service?.id}
          categoryName={center.category.category_name}
          distance={center.geo_data.distance}
          phoneNumber={center.phone_number}
          email={center.emails}
          vip={true}
          caller={props.caller}
        />
      ))}
    </>
  );
};

export default CentersVipCard;
