import { useState, useEffect } from "react";
import axios from "axios";

function useHttp(initialUrl = null, method = "GET", payload = null) {
  const [data, setData] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (customMethod = method, customUrl = initialUrl, customPayload = payload) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        method: customMethod,
        url: customUrl,
        data: customPayload,
      });

      setData(response.data);
      setHeaders(response.headers);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialUrl && method === "GET") {
      sendRequest();
    }
  }, [initialUrl, method]);

  return {
    data,
    headers,
    loading,
    error,
    sendRequest, // Puoi inviare altre richieste manualmente
  };
}

export default useHttp;
